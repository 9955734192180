import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loading from './components/layout/loading';

Sentry.init({
  dsn: 'https://19127fa0231bcccc0c6ce736f0e6d12e@o4506737640144896.ingest.sentry.io/4506744414404608',
  environment: import.meta.env.MODE || 'development',
  normalizeDepth: 7, // Truncates any data to 7 levels, default is 3
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 1% of transactions
  // Session Replay
  replaysSessionSampleRate: 0.01, // Capture 1% of sessions
  replaysOnErrorSampleRate: 1.0, // Capture 100% of errors (Note: we may reduce this if cost is too high)
});

const container = document.getElementById('root');
// TODO: Handle container being null in the future, render a page not found or enable JS? :thinking:
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
